/* eslint-disable import/order */
import { Heading, Text } from '@chakra-ui/react'
import { AcceptedQueryTypes } from '@commercetools/frontend-sdk/lib/types/Query'
import isMobile from 'ismobilejs'
import { GetServerSideProps, Redirect } from 'next'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import Head from 'next/head'
import { useRouter } from 'next/router'
import { createClient, ResponseError } from '../frontastic'
import { FrontasticRenderer } from '../frontastic/lib/renderer'
import { tastics } from '../frontastic/tastics'
import { Log } from '../helpers_composable/errorLogger'
import { sdk } from '../sdk'
import styles from './slug.module.css'
import { NEXT_PUBLIC_SITE_URL } from '../composable/components/general/constants'

type SlugProps = {
  // This needs an overhaul. Can be too many things in my opinion (*Marcel)
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any
  // data: RedirectResponse | PageDataResponse | ResponseError | { ok: string; message: string } | string;
  locale: string
}

const defaultLocale = 'en-US'
const PLP_PAGE_FOLDER_TYPE = 'composable/plp-page'
const PDP_PAGE_FOLDER_TYPE = 'composable/product-detail-page'

export default function Slug({ data }: SlugProps) {
  const router = useRouter()
  const isClient = typeof window !== 'undefined'

  const renderError = ({ data }: { data: SlugProps['data'] }) => (
    <>
      <Heading as="h1" size="xl" fontWeight="extrabold">
        Internal Error
      </Heading>
      <Text as="p" fontSize={{ base: 'mobile.body', md: 'desktop.body' }}>
        {data?.message || data}
      </Text>
      <Text as="p" fontSize={{ base: 'mobile.body', md: 'desktop.body' }}>
        Check the logs of your Frontastic CLI for more details.
      </Text>
    </>
  )

  if (!data || typeof data === 'string') {
    console.log('ERROR 1', data)
    console.log('ERROR 1 TYPE', typeof data)
    return isClient ? router.replace('/') : renderError({ data })
  }

  if (!data?.ok && data?.message) {
    console.log('ERROR 2', data)
    return isClient ? router.replace('/') : renderError({ data })
  }

  const isNonGenericPage = data?.pageFolder?.configuration?.pathTranslations.en_US === null
  const mainElements = data?.data?.dataSources
  const fallbackTitleFromCMSEntries = findTitleFromCMSEntries(mainElements)

  let seoTitle = data?.pageFolder?.configuration?.seoTitle || fallbackTitleFromCMSEntries || 'DXL'

  let seoDescription =
    data?.pageFolder?.configuration?.seoDescription ||
    fallbackTitleFromCMSEntries ||
    'Find the best deals on big & tall clothing, shoes & accessories for men from brands like Polo Ralph Lauren, Lacoste, Nautica, Reebok, Harbor Bay, and more.'

  let seoKeywords = data?.pageFolder?.configuration?.seoKeywords || fallbackTitleFromCMSEntries || ''

  if (isNonGenericPage) {
    let dynamicSEOtitle
    let dynamicSEOdescription
    let dynamicSEOmetaKeywords

    if (data?.isPDPPage && data?.data?.dataSources['__master']?.product) {
      const product = data?.data?.dataSources['__master'].product
      dynamicSEOtitle = product.metaTitle
      dynamicSEOdescription = product.metaDescription
      dynamicSEOmetaKeywords = product.metaKeywords
    }

    if (data?.isPLPPage && data?.data?.dataSources['__master']?.category) {
      const category = data?.data?.dataSources['__master'].category
      dynamicSEOtitle = category.metaTitle[defaultLocale]
      dynamicSEOdescription = category.metaDescription[defaultLocale]
      dynamicSEOmetaKeywords = category.metaKeywords[defaultLocale]
    }

    seoTitle = dynamicSEOtitle || `DXL - Shop for Big & Tall Men's Clothing & Accessories`
    seoDescription =
      dynamicSEOdescription ||
      `Find the best deals on big & tall clothing, shoes & accessories for men from brands like Polo Ralph Lauren, Lacoste, Nautica, Reebok, Harbor Bay, and more.`
    seoKeywords = dynamicSEOmetaKeywords || ''
  }

  return (
    <>
      <Head>
        <link rel="canonical" href={NEXT_PUBLIC_SITE_URL + router.asPath.split('?')[0]} key="canonical" />
        <meta httpEquiv="Content-Type" content="text/html; charset=UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />
        <meta name="description" content={seoDescription} />
        <meta name="keywords" content={seoKeywords} />
        <title>{seoTitle}</title>
      </Head>
      <FrontasticRenderer data={data} tastics={tastics} wrapperClassName={styles.gridWrapper} />
    </>
  )
}

export const getServerSideProps: GetServerSideProps | Redirect = async ({
  params,
  locale,
  query,
  req,
  res,
  resolvedUrl,
}) => {
  sdk.configureForNext(locale as string)

  const frontastic = createClient()

  const data = await frontastic.getRouteData(params?.slug as string[], query as AcceptedQueryTypes, req, res)
  const categories = await frontastic.getCategories(req, res)

  if (data) {
    if (
      (typeof data === 'object' && Object.keys(data).length === 0) ||
      (data instanceof ResponseError && data.getStatus() == 404)
    ) {
      return {
        redirect: {
          destination: '/not-found',
          statusCode: 301,
        } as Redirect,
      }
    }

    if (typeof data === 'object' && 'target' in data && 'statusCode' in data) {
      return {
        redirect: {
          destination: data.target,
          statusCode: data.statusCode,
        } as Redirect,
      }
    }
  }

  if (data instanceof Error) {
    // @TODO: Render nicer error page in debug mode, which shows the error to
    // the developer and also outlines how to debug this (take a look at
    // frontastic-CLI).
    Log.error(new Error('Error retrieving data: '), data)
    return {
      redirect: {
        destination: '/not-found',
        statusCode: 301,
      } as Redirect,
    }
  }

  if (typeof data === 'string') {
    return {
      props: {
        data: { error: data },
        error: data,
      },
    }
  }

  const protocol = req.headers.referer?.split('://')[0] || 'https'
  const serverUrl = `${protocol}://${req.headers.host}${resolvedUrl}`

  const userAgent = req.headers['user-agent']
  const isMobileDevice = isMobile(userAgent).any

  const isPLPPage = (data as any)?.pageFolder?.pageFolderType === PLP_PAGE_FOLDER_TYPE
  const isPDPPage = (data as any)?.pageFolder?.pageFolderType === PDP_PAGE_FOLDER_TYPE

  return {
    props: {
      data: { ...data, categories, serverUrl, isMobileDevice: !!isMobileDevice, isPLPPage, isPDPPage },
      locale: locale,
      ...(await serverSideTranslations(locale as string, ['common'])),
    },
  }
}

const findTitleFromCMSEntries = (cmsEntries: any): string | undefined => {
  if (typeof cmsEntries === 'object') {
    for (const key in cmsEntries) {
      const cmsEntryData = cmsEntries[key]

      if (cmsEntryData.hasOwnProperty('title')) {
        return cmsEntryData.title
      }
    }
  }
  return undefined
}
